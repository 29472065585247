export default {
  channel: {
    rows: [{
      id: 1,
      name: 'Line'
    }, {
      id: 2,
      name: 'Hotline'
    }, {
      id: 3,
      name: 'Facebook Message'
    }],
    paging: {
      rowTotal: 1
    }
  },
  search_filters: {
    name_filters: [{
      filter_count: 0,
      id: 1,
      name: 'Line'
    }, {
      filter_count: 0,
      id: 2,
      name: 'Hotline'
    }, {
      filter_count: 0,
      id: 3,
      name: 'Facebook Message'
    }]
  }
}
