<template>
  <div>
    <div class="card mb-4 custom-card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-7 mb-4">
            <!-- <b-dropdown id="dropdown-1" text="ชื่อช่องทาง"
                        class="dropdown_input_search mb-1">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
              <ul style="list-style-type: none; padding-left: 0px;">
               <li v-for="v in criteria.name_filters" :key="v.id" style="padding: .5rem 1rem; font-size: 0.875rem;">
                  <input type="checkbox" class="checkbox_search_input" :name="`checkbox_group_name${v.id}`"
                    :id="`checkbox_group_name${v.id}`" v-model="v.selected" @change="searchHandler()"/>
                  <label class="form-check-label" v-bind:for="`checkbox_group_name${v.id}`">{{v.name}} ({{v.filter_count}})</label>
                </li>
              </ul>
              </b-dropdown-text>
            </b-dropdown> -->
          </div>
          <div class="col-sm-5">
            <b-input-group class="m-3">
              <b-input-group-append>
                <b-input-group-text>
                  <b-icon icon="search" />
                </b-input-group-text>
              </b-input-group-append>
              <b-form-input placeholder="ระบุการค้นหา เช่น ชื่อช่องทาง" v-model="criteria.search" class="form-control-group" @keyup.enter.native="searchHandler()"></b-form-input>
              <b-input-group-append>
                <b-button size="md"
                          text="Button"
                          variant="primary"
                          @click="searchHandler()"
                          class="btn_border_radius_right_top_bt"
                          style="width: 86px;">
                  ค้นหา
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <!-- <div class="col-12">
            <hr class="mt-5 mb-5">
          </div>
          <div class="col-lg-4 col-md-6">
            <span style="font-size: 12px; color: #848484; font-weight: lighter;" class="m-2">
              จำนวน: <strong style="color: #000000; font-weight: bold;">{{countFilter()}} รายการ</strong> จาก {{paging.rowTotal | numberIntFormat}} รายการ
            </span>
          </div>
          <div class="col-md-6">
            <template v-for="(select, index) in criteria.name_filters">
              <span class="badge bage_selected_checkbox_search m-2" :key="`name${index}`" v-if="select.selected">
                {{select.name}}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{select.filter_count}}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)">
                  <i class="fa fa-times icon-del" style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"></i>
                </span>
              </span>
            </template>
          </div> -->
          <!-- <div class="col-lg-2 col-md-6">
            <div class="label_algin_right text-underline m-2" style="color: #848484; cursor: pointer;" @click="unSelectAllFilter()">ล้างทั้งหมด</div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="pb-4 text-right">
      <b-button size="md" variant="primary" :to="'/channel/add'" class="style_btn" v-if="permission.actionData.flag_insert"><i class="fa fa-plus"></i></b-button>
      <b-button size="md" variant="danger" v-b-modal.dialog_channel_remove :disabled="checkDisabledDelete()" class="style_btn" v-if="permission.actionData.flag_delete"><i class="far fa-trash-alt"></i></b-button>
    </div>
    <div>
      <div class="card custom-card">
        <div class="card-body">
          <div class="table-responsive padding_top_20">
            <table class="table align-middle table-bordered">
              <thead>
                <tr class="text-center" >
                  <th style="min-width: 50px; width: 1px;">เลือก</th>
                  <th @click="sortHandler('name')">ชื่อช่องทาง
                    <div class="float-right" v-if="criteria.orderBy=='name'"><i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"></i></div>
                  </th>
                  <th style="min-width: 50px; width: 1px;">ดำเนินการ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in items" :key="`list-channel-${data.id}-${index}`" :id="`checkbox_channel_index${index}`">
                  <td class="text-center checkbox_style" >
                    <b-form-checkbox :name="`checkbox_channel${data.id}`" v-model="data.selected" @change="selectedChannel(...arguments, data, index)" />
                  </td>
                  <td>{{data.name}}</td>
                  <td class="text-center" >
                    <span class="style_icon_btn" v-if="permission.actionData.flag_update">
                      <i class="fas fa-pen" @click="onEditHandler(data.id)" title="แก้ไข"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="items.length === 0" class="text-center">
                  <td colspan="3">ไม่มีข้อมูล</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row padding_top_20">
        <div  class="col-lg-1 col-md-2 col-3"
              style="margin-top: 30px;">
          <select class="form-control mb-2"
                  id="pageLimit"
                  style="width: 80px;"
                  v-model="paging.limit" v-on:change="changeLimit()">
            <option v-for="(data) in paging.pages"
                    :key="data"
                    :value="data">
              {{data}}
            </option>
          </select>
        </div>
        <div class="col-lg-11">
          <div class="row g-3"  v-if="paging.rowTotal > 0">
            <div  class="col-xl-11 col-lg-10 mb-2 text-right"
                  style="margin-top: 30px;">
              <label  class="form-label"
                      style="margin: 8px; font-size: 12px;
                      font-family: Sarabun-Light;
                      color: #818181;">
                แสดงรายการที่ <strong>{{((paging.page-1)*paging.limit)+1}} ถึง {{(paging.page) * paging.limit}}</strong> จาก <strong>{{paging.rowTotal | numberIntFormat}} รายการ </strong>
              </label>
              <paginate v-model="paging.page"
                        :first-last-button="true"
                        :page-count="paging.pageTotal"
                        :page-range="5"
                        :margin-pages="2"
                        :prev-text="'&lt;'"
                        :next-text="'&gt;'"
                        :first-button-text="'&verbar;&lt;'"
                        :last-button-text="'&gt;&verbar;'"
                        :click-handler="changePage"
                        :container-class="'pagination pagination-sm float-right m-auto paging_ul'"
                        :page-class="'page-link'"
                        :prev-class="'page-link prev_paging'"
                        :next-class="'page-link next_paging'">
              </paginate>
            </div>
            <div class="col-xl-1 col-lg-2 padding_left_none">
              <label  class="form-label"
                      style="font-size: 12px;
                      font-family: Sarabun-Light;">
                Go to page
              </label>
              <Select2 v-model="paging.page" :options="paging.pageSizes" @change="changePage(paging.page)"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <DialogChannelRemove :items="items" @successFuction="getListItems"></DialogChannelRemove>
    </div>
  </div>
</template>
<script>
  import config from '../../../util/config';
  import DialogChannelRemove from './dialog.channel.remove.vue';
  import mock from '../mock/channel';
  import $ from 'jquery';
  export default {
    name: 'channel-view-page-channel',
    data() {
      return {
        permission:{
          appSlug: 'channel',
          actionData: {}
        },
        selected: [],
        criteria: {
          search: "",
          orderBy: "",
          ascDesc: "",
          name_filters: []
        },
        mock: mock,
        page: 250,
        items: [],
        paging: {
          page: 1,
          limit: config.pagination.limit,
          pages: config.pagination.pages,
          rowTotal: 0,
          pageTotal: 0,
          pageSizes: []
        }
      };
    },
    methods: {
      onEditHandler(id){
        this.SessionStorageUtils.setSession("page-channel", id);
        this.$router.push(`/channel/edit/${id}`);
      },

      /******* local function ******/
      changeLimit(){
        this.paging.page = 1;
        this.getListItems();
      },
      selectedChannel (value, obj, indx) {
        console.log('selectedChannel ', value, obj)
        if (value) {
          this.setSelectedColumn(obj);
          $(`tr#checkbox_channel_index${indx}`).attr('class', 'highlight');
        } else {
          console.log('selected ', this.selected)
          const index = this.selected.indexOf(obj);
          if (index > -1) {
            this.selected.splice(index, 1);
          }
          console.log('selected 1', this.selected)
          $(`tr#checkbox_channel_index${indx}`).removeClass('highlight');
        }
      },
      setSelectedColumn (obj) {
        this.selected.push(obj)
        console.log('selected ', this.selected)
      },
      changePage (page) {
        this.paging.page = parseInt(""+page);
        this.getListItems();
      },
      sortHandler(orderBy){
        if(orderBy!=this.criteria.orderBy){
          this.criteria.ascDesc = "asc";
        }else{
          this.criteria.ascDesc = this.criteria.ascDesc=="asc"?"desc":"asc";
        }
        this.criteria.orderBy = orderBy;
        this.getListItems();
      },
      searchHandler(){
        this.paging.page = 1;
        this.getListItems();
      },
      async getListItems () {
        const params = `search=${this.criteria.search}&page=${this.paging.page}&limit=${this.paging.limit}&orderBy=${this.criteria.orderBy}&ascDesc=${this.criteria.ascDesc}`;
        const result = await this.HttpServices.getData(`/ab_channel?${params}`);
        if(result&&result.status.code=="200"){
          const data = result.data;
          var total = Math.ceil(data.rowTotal/this.paging.limit);
          this.paging.pageTotal = total;
          this.paging.rowTotal = data.rowTotal;
          this.items = data.data;
          this.paging.pageSizes = [];
          for(let i=1;i<=total;i++){
            this.paging.pageSizes.push({id: i, text: i});
          }
        }else{
          this.paging.pageSizes = [];
          this.paging.rowTotal = 0;
          this.items = [];
        }
      },
      checkDisabledDelete(){
        let resp = true;
        for(const v of this.items){
          if(v.selected){
            resp = false;
            break;
          }
        }

        return resp;
      },
      countFilter(){
        const filters = this.criteria.name_filters.filter((v)=>v.selected);
        return filters.length;
      },
      unSelectFilter(item){
        item.selected = false;
      },
      unSelectAllFilter(){
        this.criteria.name_filters.map((v)=>{
          v.selected = false;
        });
      },
      getFilterGroups(){
        let data = this.mock.search_filters;
        this.criteria.name_filters = data.name_filters;
      }
      /******* local function ******/
    },
    async mounted() {
      await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
      this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
      await this.getFilterGroups();
      await this.getListItems();
      this.SessionStorageUtils.deleteSession("page-channel");
    },
    components: {DialogChannelRemove},
  };
</script>
<style scope>
  .checkbox_style > .custom-control {
    left: 12px;
  }
</style>
